import { Container, Icon } from '@components/gassan-ui'
import { cn } from '@lib/cn'
import React, { FC } from 'react'

type IndicatorsProps = {
  slideLength: number
  currentIndex: number
  setCurrentIndex: (index: number) => void
}

export const Indicators: FC<IndicatorsProps> = ({ currentIndex, slideLength, setCurrentIndex }) => {
  return (
    <Container className="absolute bottom-4 left-0 right-0 z-[6] flex text-white md:bottom-8">
      {Array.from({ length: slideLength }).map((_, index) => (
        <button
          className={cn(
            'relative m-0 flex h-8 w-8 items-center justify-center p-0 text-current outline-none transition-opacity duration-150',
            index === currentIndex ? 'opacity-100' : 'opacity-30',
            'hover:opacity-100',
          )}
          key={index}
          onClick={() => setCurrentIndex(index)}
        >
          <svg
            viewBox="0 0 64 64"
            fill="none"
            className="absolute left-1/2 top-1/2 h-6 w-6 -translate-x-1/2 -translate-y-1/2"
          >
            <circle
              cx="32"
              cy="32"
              r="30"
              stroke="currentColor"
              strokeWidth="2"
              vectorEffect="non-scaling-stroke"
              className={cn(
                'hero-indicator-circle',
                index === currentIndex && 'animate-stroke-dashoffset',
              )}
            />
          </svg>
          <Icon
            icon="bullet"
            size={40}
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        </button>
      ))}
    </Container>
  )
}
