// @ts-strict-ignore
import { defaultEmarsysTracking } from './default-emarsys-tracking'
import { typedWindow } from './helpers/types'
import { CartFragmentFragment } from '@generated'
import { useEmarsysStore } from '@lib/use-emarsys-store'

type HomepageVisit = {
  customerId?: number
  cart: null | CartFragmentFragment
}

export function emarsysHomepageVisit({ customerId, cart }: HomepageVisit) {
  if (typedWindow.ScarabQueue) {
    if (typedWindow && typedWindow.ScarabQueue) {
      typedWindow.ScarabQueue.push([
        'recommend',
        {
          logic: 'HOME',
          success: handleSuccess,
          containerId: 'featured-products-home',
        },
      ])
    }

    defaultEmarsysTracking({ customerId, cart })
  }
}

function handleSuccess(SC: any) {
  const setProductIds = useEmarsysStore.getState().setProductIds

  if (SC.page?.products && SC.page?.products.length > 0) {
    setProductIds(
      'featured',
      SC.page.products.map((product) => product.id),
    )
  }
}
