import { cn } from '@lib/cn'
import React, { FC } from 'react'

type NavigationBackgroundProps = {
  variant: 'light' | 'dark'
  children: React.ReactNode
}

export const NavigationBackground: FC<NavigationBackgroundProps> = ({ variant, children }) => {
  const isLight = variant === 'light'

  return (
    <div className={cn('block', isLight ? 'text-white' : 'text-shade-800')}>
      <span
        className={cn(
          'absolute bottom-0 left-0 right-0 z-[5] block h-1/2 bg-gradient-to-t',
          isLight ? 'from-shade-800/40 to-shade-800/0' : 'from-white/40 to-white/0',
        )}
      />
      {children}
    </div>
  )
}
