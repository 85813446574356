import { Container, Icon } from '@components/gassan-ui'
import { cn } from '@lib/cn'
import React, { FC } from 'react'

type ArrowsProps = {
  handleClickNext: () => void
  handleClickPrev: () => void
}

export const Arrows: FC<ArrowsProps> = ({ handleClickNext, handleClickPrev }) => {
  return (
    <Container className="absolute bottom-4 left-0 right-0 z-[6] flex items-center justify-end gap-4 text-white md:bottom-8">
      {[handleClickPrev, handleClickNext].map((handleClick, index) => (
        <button
          key={index}
          onClick={handleClick}
          className={cn(
            'flex items-center justify-center rounded-full opacity-100 transition-all duration-150 md:opacity-70',
            'hover:opacity-100',
            index === 0 ? 'hover:-translate-x-1' : 'hover:translate-x-1',
          )}
        >
          {index === 0 ? (
            <Icon icon="slider-arrow-left" size={40} />
          ) : (
            <Icon icon="slider-arrow-right" size={40} />
          )}
        </button>
      ))}
    </Container>
  )
}
