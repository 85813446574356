import { useEffect, useState } from 'react'

const SLIDE_DURATION = 7000

// Used on both:
// -- Regular homepage
// -- Rolex homepage
export function useHomepageHeroSlider(length: number) {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % length)
    }, SLIDE_DURATION)

    return () => clearInterval(interval)
  }, [length, currentIndex])

  return {
    currentIndex,
    setCurrentIndex,
    nextSlide: () => setCurrentIndex((prev) => (prev + 1) % length),
    prevSlide: () => setCurrentIndex((prev) => (prev - 1 + length) % length),
  }
}
